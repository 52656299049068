.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 90%;
  margin-top: 80px !important;
  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

/* TextField styling */
.txt,
.txt2,
.txt3 {
  width: 100%;
  background: #2963545c;
  margin-left: 25%;
}
.txt4 {
  background: #2963545c;
}

/* Button styling */
.bt2 {
  width: 100%;
  background: #ee854e !important; /* Original color */
  color: white; /* Ensure button text is readable */
  margin-top: 20px !important; /* Add margin-top between buttons */
}
.bt {
  width: 100%;
  background: #ee854e !important; /* Original color */
  color: white; /* Ensure button text is readable */
  margin-top: 20px !important; /* Add margin-top between buttons */
}
.cardContent {
  display: flex;
  justify-content: space-between; /* Distribute sections equally */
  align-items: flex-start; /* Align items to the top */
  gap: 20px; /* Add spacing between sections */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.passwordSection {
  width: 45%; /* Take up half the width */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 15px; /* Spacing between elements */
  align-items: flex-end; /* Align content to the right */
}

.emailSection {
  width: 45%; /* Take up half the width */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 15px; /* Spacing between elements */
  align-items: flex-start; /* Align content to the left */
}

@media screen and (max-width: 768px) {
  .passwordSection,
  .emailSection {
    width: 100%; /* Full width on smaller screens */
    align-items: center; /* Center content on smaller screens */
  }
}
