.blurGreen {
  background-color: rgba(0, 255, 0, 0.1) !important;
  backdrop-filter: blur(5px);
}

.blurRed {
  background-color: rgba(255, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px);
}

.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 90%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}
.menu1 {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 250px;
  height: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 7px !important;
  margin-bottom: 10px !important;
  border: none !important;
  opacity: 1 !important;
  padding: 10px !important;
  position: relative !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.menu {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 250px;
  height: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 7px !important;
  margin-bottom: 10px !important;
  border: none !important;
  opacity: 1 !important;
  padding: 10px !important;
  position: relative !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  border: none;
  opacity: 1;
  padding: 10px;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txt {
  background: #2963545c 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #0f363b !important;
  margin: 0 auto;
  width: 250px;
}

.bt {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  margin: 90px auto;
  display: flex;
  justify-content: center;
}

.text {
  color: #0f363b;
  font: normal normal bold 17px/17px Nunito Sans Normal;
  margin: 0 auto;
  text-align: center;
}

.input,
.input2 {
  background: #2963545c;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 260px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.tabz:hover {
  background: #2963548f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.tabz {
  color: #0f363b !important;
  font-size: 16px !important;
}

.me {
  color: #0f363b !important;
  width: 250px;
}

.tb {
  margin: 30px auto;
  display: flex;
  justify-content: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.table-button {
  margin-right: 10px;
}

thead th,
tbody td {
  padding: 12px 15px;
}

.table th {
  background-color: #296354; /* Updated background color */
  color: white; /* Set text color to white for better contrast */
  padding: 12px 15px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 12px 15px; /* Add padding for spacing between cells */
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: separate; /* Use separate borders for cells */
  border-spacing: 3px; /* Add spacing between cells */
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.table-button {
  margin-right: 10px;
}

thead th,
tbody td {
  padding: 12px 15px;
}

.autoRow {
  background-color: #d4edda !important ; /* Light green */
}

.manualRow {
  background-color: #f8d7da !important; /* Light red */
}

.autoRow:hover {
  background-color: #d4edda !important; /* Light green */
}

.manualRow:hover {
  background-color: #f8d7da !important; /* Light red */
}
@media (max-width: 600px) {
  .card {
    width: 90%;
  }
}

/* Responsive table styles */
@media (max-width: 768px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td,
  .table th {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
  }
}

.table th {
  background-color: #296354; /* Updated background color */
  color: white; /* Set text color to white for better contrast */
  padding: 12px 15px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 12px 15px; /* Add padding for spacing between cells */
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: separate; /* Use separate borders for cells */
  border-spacing: 10px; /* Add spacing between cells */
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.table-button {
  margin-right: 10px;
}

thead th,
tbody td {
  padding: 12px 15px;
}

/* Responsive table styles */
@media (max-width: 768px) {
  .table,
  .table tbody,
  .table tr,
  .table td,
  .table th {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }
  .table th {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
  }
}
