.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 80%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}
/* Container styling */
.tableContainer {
  margin: 20px;
  background-color: #f3ece6 !important;

  overflow-x: auto; /* Allows horizontal scrolling on small screens */
}

/* Table styling */
.table {
  background-color: #f3ece6 !important;

  min-width: 800px; /* Ensures the table has a minimum width */
  width: 100%; /* Table takes full width of its container */
  border-collapse: separate !important;
  border-spacing: 3px 3px !important; /* Add spacing between rows and columns */
  background-color: #fff;
  margin: 0 auto; /* Center the table */
}

/* Table header styling */
.tableHead {
  background-color: #467769;
  color: white;
}

.tableHeadCell {
  padding: 20px;
  font-weight: 900 !important;
  text-align: left;

  font-size: 18px; /* Larger font for better readability */
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
  color: white !important;
}

/* Table row styling */
.tableBodyRow {
  background-color: #0cc295 !important;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease;
  color: white !important;
}

.tableBodyRow:hover {
  background-color: #ecd3a6 !important;
}

.tableCell {
  padding: 20px;
  background-color: #579888 !important;

  text-align: left;
  word-break: break-word;
  font-size: 16px;
  margin-left: 30px;
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
  color: white !important;
}

/* Button styling */
.editButton,
.cloneButton {
  padding: 10px 16px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editButton {
  background-color: #ee854e !important;
}

.editButton:hover {
  background-color: #d8744b !important;
}

.cloneButton {
  background-color: #467769 !important;
  margin-left: 35px !important;
}

.cloneButton:hover {
  background-color: #365d50 !important;
}

@media (max-width: 600px) {
  .card {
    width: 100%;
    padding: 0;
  }
  .tableContainer {
    width: 100% !important;
    margin: 0;
  }
}
