.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}

.menu,
.menu1 {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 250px;
  height: 40px;
  margin-bottom: 30px !important;
  position: relative;
  align-items: center;
  font-size: 18px !important;
}

.menu1 {
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  width: 80px;
  margin-right: 10px !important;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: last baseline;
}

.btn1 {
  background: #467769 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  position: relative;
  left: 640px;
  top: 50px;
}

.txt {
  background: #2963545c 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #0f363b !important;
  position: relative;
  left: 400px;
  width: 250px;
}

.text {
  color: #0f363b;
  font: normal normal bold 17px/17px Nunito Sans Normal;
  position: relative;
  left: 250px;
}

.input {
  background: #2963545c;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 260px;
  margin-left: 10px;
  position: relative;
  left: 30px;
  margin-bottom: 10px;
}

.input2 {
  background: #2963545c;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 260px;
  position: relative;
  left: 35px;
  margin-left: 10px;
}

.btn3 {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  width: 80px;
  margin-top: 30px !important;
}

.btn5 {
  justify-content: center;
  align-items: center;
  background: #467769 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
}

.tabz:hover {
  background: #2963548f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.tabz {
  color: #0f363b !important;
  font-size: 16px !important;
}

.me {
  color: #0f363b !important;
  width: 250px;
}

.btnzz {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  border: none;
  opacity: 1;
  padding: 10px;
  margin-bottom: 40px !important;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 600px) {
  .menu1,
  .menu {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .tb {
    left: 30px;
    top: 130px;
  }
}
.tb {
  width: 100%;
  top: 90vh;
  border-collapse: separate; /* Use separate borders to allow spacing */
  border-spacing: 3px; /* Adds 10px space between cells both horizontally and vertically */
  margin: 20px 0;
}

.tb th,
.tb td {
  padding: 10px 20px; /* Adds horizontal padding and vertical padding */
  text-align: left;
  border-radius: 4px; /* Optional: Rounded corners for the cells */
}

.tb th {
  background-color: #245648;
  color: white; /* To make text visible on the dark background */
}

.tb tbody tr {
  background-color: #467769;
  color: white; /* To match the styling of the header */
}

.tb tbody tr:nth-child(even) {
  background-color: #467769; /* Slightly different color for alternate rows (optional) */
}

.tb a {
  color: white; /* Link color on dark background */
  text-decoration: none;
}

.tb a:hover {
  text-decoration: underline; /* Adds an underline on hover for better UX */
}
