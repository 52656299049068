.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center inputs horizontally */
  justify-content: center; /* Center inputs vertically */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.MuiTab-root {
  color: black; /* Default color for tabs */
}

.Mui-selected {
  background-color: #2963545c; /* Active tab background color */
  color: white !important; /* Text color when the tab is selected */
  text-decoration-color: #2963545c;
}
.tab-list .MuiTab-root.Mui-selected {
  color: #2963545c;
  text-decoration-color: #2963545c;
}

.tab-list .MuiTabIndicator-root {
  background-color: #2963545c;
  text-decoration-color: #2963545c;
}

.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: auto; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
  min-height: 100%;
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
  margin-top: 80px !important;
}

.input2 {
  background: #2963545c !important;
  border-radius: 5px;
  border: none;
  width: 100%;
  max-width: 400px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  position: relative;
  right: 60%;
}

.menu {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  position: absolute;
  left: 150px;
  top: 63px;
  height: 45px;
}

.btn {
  background: #296354 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  border: none;
  width: 100%;
  max-width: 150px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  position: absolute;
  right: 90px;
  height: 45px;
  color: #fff !important;
  top: -10px;
}

.btn1 {
  background: #4caf50; /* Change background color */
  color: #fff; /* Change text color */
  border-radius: 10px; /* Different border radius */
  border: 2px solid #4caf50; /* Add border */
  width: 100%;
  max-width: 150px;
  margin: 50px;
  padding: 12px; /* Slightly larger padding */
  font-size: 18px; /* Larger font size */
  box-sizing: border-box;
  margin-top: 5px !important;
  position: absolute;
  left: 120px;
  height: 45px;
}

.endButton {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  color: #fff !important;
  border: none;
  width: 100%;
  max-width: 150px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: 5px !important;
  position: absolute;
  left: 120px;
  height: 45px;
  top: 35px;
}
.endButtonTimerPage {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  color: #fff !important;
  border: none;
  width: 100%;
  max-width: 150px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: 5px !important;
  position: absolute;
  height: 45px;
  top: 270px !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

@media (max-width: 600px) {
  .card {
    width: 90%;
  }
  .endButton {
    left: 20vw;
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: none;
    opacity: 1;
    padding: 10px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .input2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: none;
    opacity: 1;
    padding: 10px;
    position: relative;
    left: 50%;
    margin-top: 60px;
    transform: translate(-50%, -50%);
  }
  .endButtonTimerPage {
    top: 230px !important;
  }
}
