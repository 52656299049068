.timerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.timerBox {
  display: inline-block;
  background-color: #ffffff;
  border: 6px solid #296354; /* New Border */
  border-radius: 5px; /* New Border Radius */
  opacity: 1; /* Ensures full opacity */
  padding: 20px 40px;
  font-size: 48px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: bold;
  color: #4CAF50;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  min-width: 280px;
}

.timerImage {
  margin-left: 20px; /* Space between the timer and the image */
  width: 80px; /* Adjust size as needed */
  height: 50px; /* Maintain aspect ratio */
}

@media screen and (max-width: 600px) {
  .timerBox {
    font-size: 36px;
    padding: 15px 30px;
    min-width: 200px;
  }

  .timerImage {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 600px) and (max-width: 950px) {
  .timerBox {
    font-size: 42px;
    padding: 18px 35px;
    min-width: 240px;
  }

  .timerImage {
    width: 45px;
    height: 45px;
  }
}
