.wel {
  color: #ee854e;
  position: relative;
  left: 30% !important;
}

.a {
  text-align: left;
  font: normal normal bold 40px/50px "Lato" !important;
  margin-left: 30px;
  letter-spacing: 0px;
  color: #ee854e !important;
  opacity: 1;
}
.nav {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 60px;
  background: #296354;
  z-index: 1000;
  padding: 10px 20px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between links and menu */
  margin-left: auto;
}

.link {
  color: white !important;
  border: 3px solid #ee854e !important;
  border-radius: 9px;
  padding: 5px 10px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.link:hover {
  background: #ee854e8c 0% 0% no-repeat padding-box;
  border: 3px solid #ee854e;
  opacity: 1;
}

.menu {
  cursor: pointer;
}

.menu img {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .wel {
    display: none; /* Hide welcome message on smaller screens */
  }

  .nav {
    flex-direction: column; /* Stack items vertically */
    height: auto; /* Adjust height for content */
    padding: 10px;
  }

  .rightContainer {
    justify-content: space-evenly; /* Spread out items */
    flex-wrap: wrap; /* Wrap items for small screens */
  }

  .link {
    font-size: 14px !important;
    padding: 5px;
  }

  .menu img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 600px) {
  .a {
    position: absolute;
    left: 0;
    top: -2px;
    font-size: 26px !important;
  }
}
