.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: auto; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  margin-top: 80px !important;
  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 100%;
  max-width: 250px; /* Max width for larger screens */
  height: 40px;
  margin-bottom: 10px; /* Space between menu and button */
  font-size: 16px !important;
  text-align: center; /* Center text inside menu */
}

.btn5 {
  background: #467769 !important;
  border-radius: 7px;
  opacity: 1;
  width: 100px;
  height: 40px; /* Match button height with menu height */
  text-align: center;
  margin-top: 10px; /* Space between menu and button */
  left: 30%;
}

.btn5:hover {
  background: #467769 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    width: 90%; /* Slightly reduce width for smaller screens */
  }

  .menu {
    width: 90%; /* Full width on smaller screens */
    max-width: none; /* Remove max-width constraint */
  }

  .btn5 {
    width: 100px;
    height: 40px; /* Ensure button is same height as menu */
  }
}

@media (max-width: 480px) {
  .menu {
    font-size: 14px; /* Smaller font size on very small screens */
  }
}
