.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
  margin-top: 80px !important;
}

.DateTime {
  width: 40%;
}

/* Menu button styling */
.menu {
  background: #2963545c !important;
  color: #0f363b !important;
  height: 50px;
  font-size: 18px !important;
  width: 260px;
}

/* Task input field */
.input2 {
  background: #2963545c;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 260px;
  position: relative;
  margin-left: 10px;
}

/* Styling the submit button */
.btn {
  background: #ee854e 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  margin-top: 20px;
  width: 20%;
  margin-left: auto; /* Centers the button */
  margin-right: auto; /* Centers the button */
  display: block;
  margin-top: 10px !important;
}

/* For aligning text in a row */
.text {
  color: #0f363b;
  font: normal normal bold 17px/17px Nunito Sans Normal;
  position: relative;
  top: 46px;
  left: -200px;
}

/* Menu item styling */
.me {
  color: #0f363b !important;
  width: 250px;
}

/* Mobile responsiveness - Adjust the layout */
@media (max-width: 768px) {
  .menu,
  .input2 {
    width: 100%;
    margin-left: 0;
  }

  .btn {
    width: 100%;
  }
}
.dateTimeContainer {
  display: flex;
  justify-content: space-between; /* Space out the DateTime components */
  align-items: center; /* Align them vertically */
  gap: 20px; /* Add space between components */
  width: 100%; /* Occupy full width of the parent container */
  color: #ee854e !important;
  background-color: #f3ece6 !important;
}

.dateTimeContainer > * {
  width: 60%; /* Each DateTime takes half the width minus the gap
   */
}
@media (max-width: 768px) {
  .dateTimeContainer {
    flex-direction: column; /* Stack elements vertically */
    gap: 10px; /* Reduce the gap for smaller screens */
  }
  .card {
    width: 90% !important;
  }

  .dateTimeContainer > * {
    width: 100%; /* Full width for each component */
  }
}

.tb {
  width: 100%;
  border-collapse: separate; /* Use separate borders to allow spacing */
  border-spacing: 3px; /* Adds 10px space between cells both horizontally and vertically */
  margin: 20px 0;
}

.tb th,
.tb td {
  padding: 10px 20px; /* Adds horizontal padding and vertical padding */
  text-align: left;
  border-radius: 4px; /* Optional: Rounded corners for the cells */
}

.tb th {
  background-color: #245648;
  color: white; /* To make text visible on the dark background */
}
.card {
  width: 90% !important;
}
.tb tbody tr {
  background-color: #467769;
  color: white; /* To match the styling of the header */
}

.tb tbody tr:nth-child(even) {
  background-color: #467769; /* Slightly different color for alternate rows (optional) */
}

.tb a {
  color: white; /* Link color on dark background */
  text-decoration: none;
}

.tb a:hover {
  text-decoration: underline; /* Adds an underline on hover for better UX */
}

@media (max-width: 600px) {
  .menu1,
  .menu {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .input2 {
    margin-top: 50px;
  }
  .tb {
    left: 30px;
    top: 130px;
  }

  .btnzz {
    left: 90px;
    top: 100px;
  }
  .card {
    width: 90%;
  }
}
