.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: auto; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
  min-height: 100%;
}

.card {
  background-color: #f3ece6 !important;
  width: 80%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}
/* Container styling */
.tableContainer {
  margin: 20px;
  background-color: #f3ece6 !important;

  overflow-x: auto; /* Allows horizontal scrolling on small screens */
}

.table {
  background-color: #f3ece6 !important;
  min-width: 800px; /* Ensures the table has a minimum width */
  border-collapse: separate !important; /* Keeps border-spacing active */
  border-spacing: 3px 3px !important; /* Adds horizontal (columns) and vertical (rows) spacing */
  margin-top: 20px; /* Adjust as needed */
}

/* Table header styling */
.tableHead {
  background-color: #467769;
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
  color: white !important;
  font: bold;
}

.tableHeadCell {
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
  color: white !important;

  font-weight: 900 !important;
  font-size: 18px; /* Larger font for better readability */
}

/* Table row styling */
.tableBodyRow {
  background-color: #0cc295 !important;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease;
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
}

.tableBodyRow:hover {
  background-color: #ecd3a6 !important;
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
}

.tableCell {
  padding: 20px;
  background-color: #579888 !important;
  text-align: left;
  word-break: break-word;
  font-size: 16px;
  text-align: center !important; /* Center-align text in table headers */
  align-items: center !important;
  color: white !important;
}

/* Button styling */
.editButton,
.cloneButton {
  padding: 10px 16px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editButton {
  background-color: #ee854e !important;
}

.editButton:hover {
  background-color: #d8744b !important;
}

.cloneButton {
  background-color: #467769 !important;
}

.cloneButton:hover {
  background-color: #365d50 !important;
}
.tableContainer {
  overflow-x: auto;
  margin-bottom: 1rem;
}

.table {
  min-width: 600px; /* Ensures table width does not collapse on small screens */
  width: 100%;
  border-collapse: collapse;
}

.tableCell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tableBodyRow {
  display: table-row;
}

@media (max-width: 768px) {
  .table,
  .tableBodyRow,
  .tableHeadCell,
  .tableCell {
    display: block;
    width: 100%;
  }
  .tableContainer {
    width: 100% !important;
    margin: 0;
  }

  .tableHeadCell {
    display: none; /* Hide table headers on small screens */
  }

  .tableBodyRow {
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .tableCell {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .tableCell:last-child {
    border-bottom: none; /* Remove last row border */
  }

  .tableCell::before {
    content: attr(data-label); /* Use data-label for contextual information */
    font-weight: bold;
    margin-right: 10px;
    color: #333;
  }
  .card {
    width: 100%;
    padding: 0;
  }
}
