$Reds: (
  Light1: #e20613,
  Light2: #d71a25,
  Dark1: #c2141c,
);
$Grays: (
  Dark1: #3b3b3b,
);
$Blues: (
  Dark1: #164b60,
  Dark2: #164b607d,
  Dark3: #164b6082,
  Light1: #1b6b93,
);
$Greens: (
  Light1: #a2ff8659,
  Light2: #9efa8382,
  Light3: #9efa83,
);
$Shadows: (
  Dark1: #00000029,
  Dark2: #00000038,
  Dark3: #1b6b9300,
);
@function Reds($shade) {
  @return map-get($Reds, $shade);
}
@function Grays($shade) {
  @return map-get($Grays, $shade);
}
@function Greens($shade) {
  @return map-get($Greens, $shade);
}
@function Blues($shade) {
  @return map-get($Blues, $shade);
}
@function Shadows($shade) {
  @return map-get($Shadows, $shade);
}
