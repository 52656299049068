.card {
  width: 90%;
  max-width: 500px;
  margin: auto;
  margin-top: 5vh;
  padding: 2vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #f3ece6 !important;
}
