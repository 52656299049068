.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  height: 300px; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
  margin-top: 80px !important;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.txt,
.txt2 {
  background: #2963545c;
  border-radius: 5px;
  opacity: 1;
  color: #0f363b !important;
  width: 50% !important;
  margin-top: 10px;
}

.bt {
  background: #ee854e !important;
  border-radius: 7px;
  border: none;
  width: 30%;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .card {
    width: 90%;
  }
}
