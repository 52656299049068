.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: bottom center, right;
  height: auto;
  padding: 70px;
  position: relative;
  z-index: 1 !important;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.card {
  background-color: #f3ece6 !important; /* Card background color */
  width: 80%;
  padding: 16px;
}

.tableHeader {
  background-color: #245648; /* Table header color */
}

.tableRow {
  background-color: #579888 !important; /* Row color */
}

.saveButton {
  background-color: #54e15e !important; /* Save button color */
}

.cancelButton {
  background: #e20e0e !important ;
  color: #f3ece6 !important;
}

.unassignButton {
  background-color: #e20e0e !important; /* Unassign button color */
}

.title {
  text-align: left;
  font: normal normal bold 30px/40px "Nunito Sans", Normal;
  letter-spacing: 0px;
  color: #0f363b;
  opacity: 1;
}
.txt {
  background: #2963545c 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  outline: none;
}

.txt:hover {
  border-bottom: #0f363b 2px solid;
  outline: none;
}
