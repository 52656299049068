.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  height: 300px; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
  margin-top: 80px !important;
}

.responsiveContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.menu,
.menu1,
.btn {
  width: 100%;
  max-width: 250px;
}

.menu,
.menu1 {
  background: #2963545c !important;
  color: #0f363b !important;
  height: 40px;
  font-size: 18px;
}

.btn {
  background: #ee854e !important;
  color: #fff;
  font-weight: bold;
  width: 80px;
}

.menu:hover,
.menu1:hover,
.btn:hover {
  background-color: #296354 !important;
}

.btn1 {
  background: #467769 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  position: relative;
  left: 640px;
  top: 50px;
}
.txt {
  background: #2963545c 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1;
  color: #0f363b !important;
  position: relative;
  left: 400px;
  width: 250px;
}
.bt {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  position: relative;
  top: 90px;
  left: 230px;
}

.text {
  color: #0f363b !important;
  font: normal normal bold 17px/17px Nunito Sans Normal;
  position: relative;
  left: 250px;
}
.input {
  background: #2963545c !important;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 260px;
  margin-left: 10px;
  position: relative;
  left: 30px;
  margin-bottom: 10px;
}

.input2 {
  background: #2963545c !important;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 260px;
  position: relative;
  left: 35px;
  margin-left: 10px;
}

.btn3 {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  position: relative;
  left: 630px;
  top: 35px;
  width: 80px;
  margin-top: 30px !important;
}
.btn5 {
  justify-content: center;
  align-items: center;
  background: #467769 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
}
.tabz:hover {
  background: #2963548f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}
.tabz {
  color: #0f363b !important;
  font-size: 16px !important;
}
.me {
  color: #0f363b !important;
  width: 250px;
}
.btnzz {
  justify-content: center;
  align-items: center;
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  border: none;
  opacity: 1;
  position: relative;
  left: 150px;
  top: 30px;
}
.tb {
  position: relative;
  left: 150px;
  top: 30px;
}
@media (max-width: 600px) {
  .card {
    width: 90% !important;
  }
}
