@import "../src/intro/assets/scss/colors.module.scss";

.tit {
  text-align: right;
  font: normal normal bold 26px/83px "Lato";
  letter-spacing: 0px;
  color: #ee854e;
  text-shadow: 5px 13px 6px #00000029;
  opacity: 1;
  position: absolute;
  top: 14%;
  left: 2%;
  transform: translateY(0);
  z-index: 999999;

  @media (max-width: 768px) {
    font-size: 24px;
    right: 20px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    bottom: auto;
    top: 80px;
    right: 280px;
  }
}

.introDiv {
  background: linear-gradient(3deg, #296354, #296354);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.75s ease;

  @media (max-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
}

.logo {
  background-image: url(../src/intro/img/Asset\ 4.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 140px;
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    height: 90px;
    width: 160px;
  }

  @media (max-width: 480px) {
    height: 70px;
    width: 120px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 2000px) {
  .tit {
    top: 11%;
  }
}

.introDivFade {
  animation: fade-out 0.75s ease-out both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
