.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 70%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}
.card2 {
  background-color: #467769 !important;
  width: 70%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}

.menuContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.menu {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 100%;
  max-width: 250px;
  height: 40px;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center; /* Center-align the button for consistency */
  margin-bottom: 20px;
}

.btnzz {
  background: #ee854e !important;
  color: #fff;
  border-radius: 7px;
  border: none;
  height: 40px;
  width: 200px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.btnzz:hover {
  background: #ee854e !important;
}
.dateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  max-width: 500px;
  gap: 10px;
}

.datePicker {
  width: 100%;
  max-width: 200px;
  font-size: 14px;
  padding: 5px;
  color: #0f363b;
}

.totalTime {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.dataList {
  list-style-type: none;
  padding: 0;
}

.dataItem {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #ee854e;
  border-radius: 50%;
}

.username,
.duration {
  font-family: Arial, sans-serif;
  color: #0f363b;
}

@media screen and (max-width: 768px) {
  .card {
    width: 95%;
    padding: 10px;
  }

  .menu {
    max-width: 200px;
    font-size: 16px;
  }

  .btnzz {
    width: 150px;
    font-size: 14px;
  }

  .dateContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .datePicker {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%;
    padding: 10px;
  }

  .menu {
    font-size: 14px;
  }

  .btnzz {
    width: 180px;
    font-size: 12px;
    padding: 14px !important;
  }

  .totalTime {
    font-size: 18px;
  }

  .dataItem {
    font-size: 16px;
  }
}
