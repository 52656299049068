/* Report.module.css */
.bg {
  justify-content: center;
  align-items: center;
  overflow: auto;
  text-align: center;
}
.btn {
  margin: 10px; /* Add space between buttons */
}

.customDate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #296354 !important;
}

.customDateLabel {
  margin: 0 5px;
}

.customDateInput {
  margin: 0 10px;
  padding: 5px;
  color: #ee854e !important;
  background-color: #f7f3f0 !important;
}
.btn {
  background: #ee854e !important;
  border-radius: 7px;
  opacity: 1;
  border: none;
  margin-right: 30px;
  cursor: pointer;
  padding: 10px;
  color: white !important;
}

.tb {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Adjust as needed */
}

.tb th,
.tb td {
  padding: 8px; /* Adjust padding for better spacing */
  text-align: left; /* Align text left */
}

.tb th {
  background-color: #f2f2f2; /* Light gray background for table header */
}

@media screen and (max-width: 600px) {
  .bg {
    height: auto; /* Adjust height for responsiveness */
    padding: 30px; /* Adjust padding for smaller screens */
  }

  .tb {
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}

/* Report.module.css */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #45a049;
}

.pagination button.active {
  background-color: #ee854e;
}
.customDate {
  margin-bottom: 20px; /* Adjust margin as needed */
}

.customDateLabel {
  font-weight: bold;
  margin-right: 10px; /* Adjust spacing */
}

.customDateInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Add more styles as per your design */
}
/* Report.module.css */
.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0;
  background-repeat: no-repeat;
  background-size: 250px, 150px;
  background-position: right, left top, bottom;
  height: 1550px;
  padding: 50px;
  position: relative;
}

.btn {
  background: #ee854e;
  border-radius: 7px;
  opacity: 1;
  border: none;
  margin-right: 30px;
  cursor: pointer; /* Ensure buttons are clickable */
}

.tb {
  width: 100%;
  border-collapse: separate;
  border-spacing: 3px; /* Space between cells */
  margin-top: 20px; /* Adjust as needed */
}

.tb th,
.tb td {
  padding: 8px; /* Adjust padding for better spacing */
  text-align: left; /* Align text left */
  /* background-color: #2963545C; */
}

.tb th {
  background-color: #296354; /* Background color for table header */
  color: white; /* Text color for table header */
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #45a049;
}

.pagination button.active {
  background-color: #ee854e;
}

.customDate {
  margin-bottom: 20px; /* Adjust margin as needed */
}

.customDateLabel {
  font-weight: bold;
  margin-right: 10px; /* Adjust spacing */
}

.customDateInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Add more styles as per your design */
}

@media screen and (max-width: 600px) {
  .bg {
    height: auto; /* Adjust height for responsiveness */
    padding: 30px; /* Adjust padding for smaller screens */
  }

  .tb {
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}

.paidRow {
  background-color: rgba(0, 255, 0, 0.1) !important;
  backdrop-filter: blur(5px);
}

.unpaidRow {
  background-color: rgba(255, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px);
}

.unpaidApprovedRow {
  background-color: rgba(255, 166, 0, 0.379) !important;
  backdrop-filter: blur(5px);
}
