.bg {
  background-image: url("../img/login.png");
  background-repeat: no-repeat;
  background-size: 13%;
  background-position: left 20px top 20%;
}

* {
  overflow-wrap: break-word;
  font-family: "Lato";
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  padding: 1vh 2vw;
  font-size: 1.5vw;
}

.title {
  font-size: 36px;
  color: #0f363b;
  position: absolute;
  top: 18vh;
  left: 4.5vw;
}

.card {
  width: 90%;
  max-width: 500px;
  margin: auto;
  margin-top: 5vh;
  padding: 2vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #f3ece6 !important;
}

.text {
  margin-right: 3vw;
  color: #0f363b;
  font: normal normal bold 1.5vw/1.5 "Lato" Normal;
  flex: 3;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2vh;
}

.input {
  background: #2963545c !important ;
  border-radius: 5px;
  border: none;
  height: 6vh;
  padding: 1vh;
  width: 100%;
}
.linksContainer {
  display: flex;
  flex-direction: column; /* Stack the links vertically */
  align-items: center; /* Center align the links */
  margin-top: 2vh;
}

.register {
  align-self: center;
  margin-top: 3vh;
  color: #467769 !important;
  text-decoration: none;
}

.forgetPassword {
  align-self: center;
  margin-top: 2vh;
  color: #467769 !important;
  text-decoration: none;
}

.forgetPassword:hover,
.register:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .text {
    font: normal normal bold 3.5vw/1.5 "Lato" Normal;
    margin-right: 0;
    margin-bottom: 1vh;
  }
  .title {
    top: 8vh;
  }
  .card {
    top: 25vh !important;
    position: relative;
  }
  .bg {
    background-size: 35%;
    background-position: left top 5%;
  }
  .btn {
    font-size: 14px;
  }
}
