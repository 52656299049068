.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 60%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}

.text {
  background: #2963545c 0% 0% no-repeat padding-box;
}

.text {
  background: #2963545c 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: none;
  opacity: 1;
  padding: 10px;
  position: relative;
  left: 50%;
  margin-top: 7px !important;
  transform: translate(-50%, -50%);
}

.btn3 {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
  position: relative;
  left: 47%;
  top: 20px;
  width: 80px;
  margin-top: 10px !important;
}

@media screen and (min-device-width: 600px) and (max-width: 950px) {
  .input,
  .input2,
  .btnzz,
  .text,
  .txt {
    left: 40px;
  }
  .btn,
  .btn1,
  .btn3,
  .btn5 {
    left: 310px;
    padding-right: 5px;
  }
}

@media (max-width: 600px) {
  .card {
    width: 90%;
  }
}
