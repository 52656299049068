.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* border: solid BLUE 1px; */
}

.content {
  margin-top: 60px;
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right;
  height: auto;
  position: relative;
  z-index: 0;
  min-height: calc(100% - 60px);
  overflow: auto;
  /* border: solid orange 1px; */
}
