.bg {
  background-image: url("../img/circle.png"), url("../img/circle.png");
  background-color: #f7f3f0 !important;
  background-repeat: no-repeat;
  background-size: 300px, 250px;
  background-position: bottom center, right; /* Position both images distinctly */
  height: 100vh; /* Full height to contain the card and images */
  padding: 20px;
  position: relative; /* Makes this container a reference point */
  z-index: 0; /* Keeps it behind the card */
}

.card {
  background-color: #f3ece6 !important;
  width: 80%;
  margin-top: 80px !important;

  height: auto; /* Ensures it expands based on content */
  padding: 20px;
  margin: 0 auto 40px auto; /* Center horizontally and add bottom margin */
  position: relative; /* Keeps it within the context of .bg */
  z-index: 1; /* Elevates it above .bg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
  border-radius: 8px; /* Optional for smooth corners */
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.tableHeader {
  background-color: #245648; /* Table header color */
}

.tableRow {
  background-color: #579888 !important; /* Row color */
}

.saveButton {
  background-color: #54e15e !important; /* Save button color */
}

.cancelButton {
  background: #e20e0e !important ;
  color: #f3ece6 !important;
}

.unassignButton {
  background-color: #e20e0e !important; /* Unassign button color */
}

.title {
  text-align: left;
  font: normal normal bold 30px/40px "Nunito Sans", Normal;
  letter-spacing: 0px;
  color: #0f363b;
  opacity: 1;
}
.txt {
  background: #2963545c 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  outline: none;
}
.menu {
  background: #2963545c 0% 0% no-repeat padding-box;
}

.txt:hover {
  border-bottom: #0f363b 2px solid;
  outline: none;
}
