.bg {
  /* background-image: url("./img/circle.png"), url("./img/circle.png"); */
  background-color: #ada9a980;
  background-repeat: no-repeat;
  background-size: 250px, 250px;
  background-position: bottom, right;
  height: 100vh;
  z-index: 1000;
  /*border: solid 1px red;*/
  position: fixed;
  top: 60px;
  width: 100% !important;
}
.accordion {
  margin-top: none !important;
  padding: 0;
}

.accordion-details {
  padding: 0;
}

section {
  margin: 0;
  padding: 0;
}

.card {
  width: 80%;
  max-width: 1150px;
  height: auto;
  margin: 20px auto;
  margin-top: 50px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #f3ece6 !important;
  position: relative;
  z-index: -1 !important;
}

/* Menu adjustments */
.menu {
  background: #2963545c !important;
  color: #0f363b !important;
  width: 80%; /* Adjust width to be responsive */
  height: 40px;
  margin-bottom: 30px !important;
  position: relative;
  left: 0;
  font-size: 18px !important;
}

.menu1 {
  position: relative;
  top: 50px;
  background: #2963545c !important;
  color: #0f363b !important;
  width: 80%; /* Adjust width to be responsive */
  height: 40px;
  margin-bottom: 30px !important;
}

.btn {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  width: 80px;
  margin-right: 10px !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%); /* Center the button horizontally */
}

.btn1 {
  background: #467769 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  position: relative;
  left: 50%;
  transform: translateX(-50%); /* Center the button horizontally */
}

.txt {
  background: #2963545c;
  border-radius: 5px;
  color: #0f363b !important;
  width: 80%;
  margin: 0 auto; /* Center the text input */
}

.bt {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  position: relative;
  top: 90px;
  left: 50%;
  transform: translateX(-50%); /* Center the button horizontally */
}

.text {
  color: #0f363b;
  font: normal normal bold 17px/17px Nunito Sans Normal;
  position: relative;
  left: 50%;
  transform: translateX(-50%); /* Center the text */
}

.input,
.input2 {
  background: #2963545c;
  border-radius: 5px;
  border: none;
  height: 50px;
  width: 80%;
  margin-left: 10px;
  margin-bottom: 10px;
  margin: 0 auto; /* Center the input fields */
}

.btn3 {
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  width: 80px;
  position: relative;
  left: 50%;
  transform: translateX(-50%); /* Center the button horizontally */
}

.btn5 {
  justify-content: center;
  align-items: center;
  background: #467769 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  opacity: 1;
}

.tabz:hover {
  background: #2963548f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.tabz {
  color: #0f363b !important;
  font-size: 16px !important;
}

.me {
  color: #0f363b !important;
  width: 250px;
}

.btnzz {
  justify-content: center;
  align-items: center;
  background: #ee854e 0% 0% no-repeat padding-box !important;
  border-radius: 7px;
  border: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%); /* Center the button horizontally */
}

.tb {
  position: relative;
  left: 50%;
  transform: translateX(-50%); /* Center the table horizontally */
}

.meu {
  background-color: #ee854e !important;
}

.meu:hover {
  background-color: #467769 !important;
}
